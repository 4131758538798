import dynamic from 'next/dynamic'
import Head from 'next/head'
import nookies from 'nookies'
import { wrapper } from '@redux/store'
import { fetchLang } from '@redux/modules/global'
import { loadTranslations } from '@utils/translations'
import ErrorLayout from '@shared/layouts/Error'

const ContainerError404 = dynamic(
  () => import('@containers/Errors/Custom404'),
  { ssr: false }
)

export default function Custom404(props) {
  return (
    <>
      <Head>
        <title>Apolo</title>
        <meta name="description" content="Apolo - Not Found" />
      </Head>
      <ContainerError404 {...props} />
    </>
  )
}

Custom404.getLayout = ErrorLayout
