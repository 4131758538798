import React from 'react'
import { useError } from './useError'
import Header from '@asdeporte/apolo-components/cjs/Header'

import '@asdeporte/apolo-components/es/Header/style/index.css'

interface IErrorLayoutProps {
  children: React.ReactNode
  lng: string
}

const ErrorLayout: React.FC<IErrorLayoutProps> = ({ children, lng }) => {
  const { hydrated } = useError({ lng })

  return (
    <>
      {hydrated && (
        <>
          <Header hideOptions />

          <div className="wrapper">{children}</div>
        </>
      )}
    </>
  )
}

export default ErrorLayout
