// https://asdassets.s3.us-west-1.amazonaws.com/upload/2023/6/1687364714423_error-404.png
import { useEffect, useState } from 'react'

export interface IUseErrorProps {
  lng: string
}

export const useError = ({ lng }: IUseErrorProps) => {
  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    const init = () => {
      setHydrated(true)
    }
    init()
  }, [])

  return {
    hydrated,
  }
}
